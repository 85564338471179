/* https://stackoverflow.com/questions/5898776/how-to-refer-to-static-files-in-my-css-files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
   font-family: "Gilroy";
   src: url("./fonts/Gilroy-Medium.woff2") format("woff2"),
      url("./fonts/Gilroy-Medium.woff") format("woff");
   font-weight: 500;
   font-style: medium;
   font-display: swap;
}

@font-face {
   font-family: "Gilroy";
   src: url("./fonts/Gilroy-Bold.woff2") format("woff2"),
      url("./fonts/Gilroy-Bold.woff") format("woff");
   font-weight: 700;
   font-style: bold;
   font-display: swap;
}

@font-face {
   font-family: "Gilroy";
   src: url("./fonts/Gilroy-Regular.woff2") format("woff2"),
      url("./fonts/Gilroy-Regular.woff") format("woff");
   font-weight: 400;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Gilroy";
   src: url("./fonts/Gilroy-Light.woff2") format("woff2"),
      url("./fonts/Gilroy-Light.woff") format("woff");
   font-weight: 300;
   font-style: light;
   font-display: swap;
}

@font-face {
   font-family: "Indie Flower";
   src: url("./fonts/IndieFlower-Regular.woff2") format("woff2"),
      url("./fonts/IndieFlower-Regular.woff") format("woff");
   font-weight: 400;
   font-style: normal;
   font-display: swap;
}

/* custom tailwind classes */
@layer components {
   .btn-regular {
      @apply px-5 py-[0.85rem] border-[1px] font-bold cursor-pointer rounded-xl border-ay-green text-white bg-ay-green flex items-center justify-center hover:bg-ay-green hover:border-ay-green;
   }

   .btn-outline {
      @apply px-5 py-[0.85rem] border-[1px] font-bold cursor-pointer rounded-xl border-ay-green text-ay-green bg-white flex items-center justify-center hover:bg-ay-green hover:text-white hover:border-ay-green transition-colors duration-200 ease-in-out;
   }

   .btn-turqoise {
      @apply px-5 py-[0.85rem] border-[1px] font-bold cursor-pointer rounded-xl border-ay-turqoise text-white bg-ay-turqoise flex items-center justify-center hover:bg-ay-turqoise hover:border-ay-turqoise uppercase;
   }

   .btn-danger {
      @apply px-5 py-[0.85rem] border-[1px] font-bold cursor-pointer rounded-xl border-ay-red text-white bg-ay-red flex items-center justify-center hover:bg-ay-red hover:border-ay-red;
   }
}

/* Add this for hiding the scrollbar */
.custom__scrollbar-hidden::-webkit-scrollbar {
   display: none;
 }

 .custom__scrollbar-hidden {
   -ms-overflow-style: none; /* For Internet Explorer and Edge */
   scrollbar-width: none; /* For Firefox */
 }


/* <PortalStudentCarousel /> */

.swiper-pagination {
   margin-bottom: 2rem !important;
}

.swiper-pagination-bullet {
   width: 6px !important;
   height: 6px !important;
   background: gray !important;
   margin: 0 3px !important;
}

/* other */

.fw-medium {
   font-weight: 500;
}

.break-out-container {
   width: 100vw;
   margin-inline-start: 50%;
   transform: translate(-50%);
}

.splash {
   display: flex;
   z-index: 200;
   background: linear-gradient(to bottom,
         rgb(245, 245, 245),
         rgb(255, 255, 255)) !important;
}

@keyframes cliploader {
   0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
   }

   50% {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
   }

   100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

@keyframes fadeinout {
   0% {
      opacity: 1;
   }

   50% {
      opacity: 0;
   }

   75% {
      opacity: 1;
   }

   100% {
      opacity: 1;
   }
}

.fade-in {
   opacity: 1;
   animation: fadeinout 2s ease-in infinite;
}

::-moz-selection {
   /* Code for Firefox */
   color: white;
   background: #1dc468;
}

::selection {
   color: white;
   background: #1dc468;
}

.hoverable-shadow {
   transition: 0.3s;
   box-shadow: none;
}

.hoverable-shadow:hover {
   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
   transition: 0.3s;
}

.hoverable {
   background-color: transparent;
   transition: 0.3s;
}

.hoverable:hover {
   background-color: #f5f5f5;
   transition: 0.3s;
}

.bg-green {
   background-color: #1dc468 !important;
}

.bg-darkgreen {
   background-color: #142b26 !important;
}

.bg-lightgreen {
   background-color: rgba(29, 196, 104, 0.06) !important;
}

.bg-gray {
   background-color: #e2e2e2 !important;
}

.bg-lightgray {
   background-color: #f5f5f5 !important;
}

.bg-turqoise {
   background-color: #017c72 !important;
}

.text-green {
   color: #1dc468 !important;
}

.text-turqoise {
   color: #017c72 !important;
}

.border-green {
   border-color: #1dc468 !important;
}

.border-gray {
   border-color: #d2d2d2 !important;
}

.border-lightgray {
   border-color: #dddd !important;
}

.text-black-50 {
   color: #b5b5b5 !important;
}

.ay-rounded-small {
   border-radius: 0.25rem;
}

.ay-rounded {
   border-radius: 8px;
}

.ay-rounded-max {
   border-radius: 12px;
}

.ay-rounded-sm-max {
   border-radius: 10px;
}

.ay-button {
   white-space: nowrap;
   display: inline-block;
   line-height: 1.5;
   padding: 0.85rem 1rem 0.85rem 1rem !important;
   text-align: center;
   text-decoration: none;
   vertical-align: middle;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   border-radius: 12px;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ay-button-outline {
   white-space: nowrap;
   display: inline-block;
   color: #1dc468;
   font-weight: 500;
   line-height: 1.5;
   border: 1px solid #1dc468;
   padding: 0.85rem 1rem 0.75rem 1rem !important;
   text-align: center;
   text-decoration: none;
   vertical-align: middle;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   user-select: none;
   background-color: transparent;
   border-radius: 12px;
   font-size: 14px !important;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ay-button-outline:hover {
   color: white;
   background-color: #1dc468;
   transition: all 0.3s;
}

.letter-close {
   letter-spacing: -1px;
}

.letter-close-max {
   letter-spacing: -3px;
}

.cursor-pointer {
   cursor: pointer !important;
}

.z-1 {
   z-index: 1 !important;
}

.z-0 {
   z-index: -1 !important;
}

.w-10 {
   width: 10%;
}

.w-15 {
   width: 15%;
}

.w-80 {
   width: 80%;
}

.w-fit {
   width: fit-content !important;
}

.h-20 {
   height: 20%;
}

.vh-80 {
   height: 80vh;
}

.vh-90 {
   height: 90vh;
}

.start-10 {
   left: 11%;
}

.start-15 {
   left: 14.5%;
}

.start-40 {
   left: 47%;
}

.start-85 {
   left: 85%;
}

.start-90 {
   left: 89%;
}

.top-40 {
   top: 40%;
}

.top-45 {
   top: 47%;
}

.p-blip {
   padding: 2px !important;
}

.pt-half {
   padding-top: 0.08rem;
}

.p-6 {
   padding: 6rem;
}

.px-6 {
   padding-left: 6rem;
   padding-right: 6rem;
}

.py-6 {
   padding-top: 6rem;
   padding-bottom: 6rem;
}

.py-xl-6 {
   padding-top: none;
   padding-bottom: none;
}

.px-xl-6 {
   padding-left: none;
   padding-right: none;
}

.ps-6 {
   padding-left: 6rem;
}

.pe-6 {
   padding-right: 6rem;
}

.pt-6 {
   padding-top: 6rem;
}

.me-6 {
   margin-right: 10rem;
}

.-ms-3 {
   margin-left: -1rem;
}

.gap-6 {
   gap: 6rem;
}

/* bootstrap fix - login page checkbox */
.nav-link{
   padding: 0.85rem 1rem 0.85rem 1rem !important;
}

/* login page checkbox */
.nav-link.active,
.show>.nav-link {
   border-radius: 11px;
   background-color: white;
   border: 1px solid #1dc468;
   /* transform: scale(1.04);
   transition: transform 0.1s ease-in; */
}

.nav-link:focus,
.nav-link:hover {
   color: #1dc468;
}

.nav-link {
   color: #1dc468;
}

.accordion-button:not(.collapsed)::after {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231dc468'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button::after {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231dc468'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button:is(.collapsed) {
   border-top: 1px solid #dee2e6 !important;
   color: black;
   background-color: transparent;
}

.accordion-button:not(.collapsed) {
   border-top: 1px solid #dee2e6 !important;
   color: black;
   background-color: transparent;
}

.accordion-button:focus {
   z-index: 3;
   border-color: transparent;
   outline: 0;
   box-shadow: none;
}

.accordion-button {
   border: none;
   font-weight: 600;
   padding: 2em 0;
   text-align: start;
   line-height: 1.5;
}

.accordion-collapse {
   border-bottom: none !important;
}

.btn:focus,
.btn:active {
   outline: none !important;
   /* box-shadow: 0 0 0 0.25rem rgba(29, 196, 104, 0.25); */
   box-shadow: none !important;
   opacity: 1;
}

.form-check-input:checked {
   background-color: #1dc468;
   border-color: #1dc468;
}

.form-check-input:focus {
   border-color: transparent;
   outline: 0;
   box-shadow: 0 0 0 0.25rem rgba(29, 196, 104, 0.25);
}

button:focus {
   outline: none !important;
}

button:disabled,
button[disabled] {
   opacity: 1 !important;
}

hr {
   border-top: 1px solid #f5f5f5;
   border-bottom: #f5f5f5;
   border-right: #f5f5f5;
   border-left: #f5f5f5;
   opacity: 1 !important;
   background-color: transparent !important;
}

p,
span,
textarea,
textarea::placeholder,
.form-control {
   font-size: clamp(0.5rem, 3.65vw, 1rem);
   line-height: 28px;
}

.xsmall {
   font-size: 0.75em;
}

html {
   overflow-y: scroll;
   -webkit-tap-highlight-color: transparent;
}

body,
#root,
.App {
   font-family: "Gilroy", "Helvetica", "Indie Flower" !important;
   background-color: white;
   font-weight: 500;
}

.shadow {
   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.modal-header .close {
   padding: 1rem;
   margin: -1rem -1rem -1rem auto;
}

button.close {
   padding: 0;
   background-color: transparent;
   border: 0;
}

.cheeseburger-menu-outer {
   transition: transform 0.6s !important;
   transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1) !important;
}

.close {
   float: right;
   font-size: 2.3rem;
   font-weight: 400;
   line-height: 1;
   color: #000;
   text-shadow: 0 1px 0 #fff;
   opacity: 0.5;
}

a {
   transition: all 0.15s ease;
}

a:any-link {
   color: black;
   background-color: transparent;
   text-decoration: none;
}

a:hover {
   color: #1dc468;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
   left: -1px;
   border-width: 0.4rem 0 0.4rem 0.4rem;
   border-left-color: #e2e2e2;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
   right: -1px;
   border-width: 0.4rem 0.4rem 0.4rem 0;
   border-right-color: #e2e2e2;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
   top: -1px;
   border-width: 0.4rem 0.4rem 0;
   border-top-color: #e2e2e2;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
   bottom: -1px;
   border-width: 0 0.4rem 0.4rem;
   border-bottom-color: transparent;
}

.tooltip {
   font-family: inherit;
}

.tooltip.show {
   opacity: 1;
}

.trx-tooltip {
   font-family: inherit;
}

.trx-tooltip.show {
   opacity: 1;
}

.tooltip-inner {
   max-width: 280px;
   padding: 1rem;
   color: black;
   text-align: left;
   background-color: white;
   border: 1px solid #d2d2d2;
   border-radius: 0.25rem;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

#button-tooltip-trx>.tooltip-inner {
   max-width: 320px;
   gap: 1rem;
   display: flex;
   justify-content: space-between;
   padding: 1rem;
   color: black;
   text-align: left;
   background-color: white;
   border: 1px solid #d2d2d2;
   border-radius: 8px;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.bumper {
   height: 55px;
}

textarea {
   border: 1px solid #1dc468;
   transition: 0.3s;
   outline: none;
   padding: 1rem;
}

.PhoneInputInput {
   padding: 10px;
   width: 100%;
   font-size: 13px;
   background-color: #f5f5f5;
}

input {
   border: transparent;
   transition: 0.3s;
   outline: none;
}

input::placeholder {
   color: #bdbdbd;
}

textarea::placeholder {
   color: #bdbdbd;
}

/* input:placeholder-shown,
textarea:placeholder-shown {
   border: 0.5px solid transparent;
} */

.ay-alert-success {
   color: #1dc468;
   background-color: #d8f5e5;
}

.ay-alert-primary {
   color: #0071a9;
   background-color: #eef9fd;
}

.ay-alert-info {
   color: #f79f1a;
   background-color: #fff8e6;
}

.ay-alert-danger {
   color: #e74c3c;
   background-color: #ffebec;
}

.ay-textarea {
   border: 1px solid #d2d2d2;
}

.ay-textarea::placeholder {
   font-size: 1rem;
}

.ay-input:focus,
.ay-textarea:focus {
   border: 1px solid #1dc468 !important;
   border-top: 1px solid #1dc468 !important;
   border-bottom: 1px solid #1dc468 !important;
   border-left: 1px solid #1dc468 !important;
   border-right: 1px solid #1dc468 !important;
}

.active-search-input:focus {
   border: none !important;
}

.active-search-input:placeholder-shown {
   border: 0px;
}

.otp-input {
   border: 1px solid lightgray;
   width: 4rem !important;
   height: 4rem !important;
   text-align: center;
   border-radius: 0.8rem;
   font-size: 20px;
   font-weight: bold;
}

.otp-input:focus {
   border: 1px solid #1dc468 !important;
}

input[disabled],
textarea[disabled] {
   color: black;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

input[type="number"] {
   -moz-appearance: textfield;
}

.display-xl {
   font-size: clamp(3rem, 7vw, 5rem);
   letter-spacing: -3px;
}

.display-7 {
   font-size: clamp(1.6rem, 7vw, 1.9rem);
}

.display-8 {
   font-size: clamp(1.8rem, 8.2vw, 4.2rem);
}

.h7 {
   font-size: clamp(1rem, 1.05rem, 1.1rem);
}

.navbar-toggler:focus {
   box-shadow: none;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
   display: flex;
   flex-wrap: inherit;
   align-items: center;
   justify-content: space-between;
}

.fade-appear,
.fade-enter {
   opacity: 0;
   z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
   opacity: 1;
   transition: opacity 300ms linear 150ms;
}

.fade-exit {
   opacity: 1;
}

.fade-exit.fade-exit-active {
   opacity: 0;
   transition: opacity 150ms linear;
}

.bg-wrapper {
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}

.img-cover {
   object-fit: cover;
}

.dark-filter {
   background: rgba(0, 0, 0, 0.6);
}

.text-divider {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.text-divider::before {
   content: "";
   height: 1px;
   background: #d2d2d2;
   flex: 1;
   margin: 0 0.7em 0 0;
}

.text-divider::after {
   content: "";
   height: 1px;
   background: #d2d2d2;
   flex: 1;
   margin: 0 0 0 0.7em;
}

.v-center {
   display: flex;
   align-items: center;
}

.custom-loader {
   position: absolute;
   top: 40%;
   transform: translateY(-50%);
   margin: auto;
   left: 45%;
}

.dropdown-item:hover {
   transition: all 0.2s;
   color: #1dc468;
   text-decoration: none;
}

.dropdown-item:active {
   background-color: transparent;
}

.PhoneInputCountrySelectArrow {
   display: block !important;
   content: "" !important;
   width: 0.3em !important;
   height: 0.3em !important;
   margin-left: 0.35em !important;
   border-style: solid !important;
   border-color: #d2d2d2 !important;
   border-top-width: 0 !important;
   border-bottom-width: 1px !important;
   border-left-width: 0 !important;
   border-right-width: 1px !important;
   transform: rotate(45deg) !important;
   opacity: 1 !important;
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon+.PhoneInputCountrySelectArrow {
   border-color: #1dc468 !important;
}

.PhoneInputCountryIconImg {
   border-radius: 0.1rem !important;
}

.embla {
   overflow: hidden;
}

.embla__school_container {
   display: flex;
}

.embla__app_container {
   display: flex;
   margin-left: 55px;
   margin-right: 55px;
}

.embla__school_slide {
   flex: 0 0 80%;
   /* Slide covers 50% of the viewport */
   min-width: 0;
   margin-right: 10px;
   margin-left: 10px;
}

.embla__app_slide {
   flex: 0 0 75%;
   /* Slide covers 50% of the viewport */
   min-width: 0;
   margin-right: 60px;
   /* margin-left: 30px; */
}

.embla__app_slide:last-child {
   margin-right: 0px;
   margin-left: 0px;
}

.graduate-carousel>.swiper-pagination-bullet {
   width: 8px;
   height: 8px;
   display: inline-block;
   border-radius: 50%;
   background: gray !important;
   opacity: 1 !important;
}

.swiper-pagination-bullet-active {
   opacity: 1 !important;
   background: #1dc468 !important;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
   bottom: 40px !important;
}

.swiper-arrow-left {
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 95%);
}

.swiper-arrow-right {
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 5%);
}

.swiper-arrow-left-settings {
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 95%);
}

.swiper-arrow-right-settings {
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 5%);
}

.swiper-arrow-left-portal {
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 93.5%);
   z-index: 5;
}

.swiper-arrow-right-portal {
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 6.5%);
   z-index: 5;
}

.swiper-arrow-left-settings {
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 95%);
}

.swiper-arrow-right-settings {
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 5%);
}

.swiper-arrow-left-congo {
   cursor: pointer;
   position: absolute;
   top: 46%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 92%);
}

.swiper-arrow-right-congo {
   cursor: pointer;
   position: absolute;
   top: 46%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 8%);
}

/* progress photo pc */
.swiper-arrow-left-progress {
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 93%);
}

.swiper-arrow-right-progress {
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 7%);
}

/* progress photo mobile */
.student-progress-photo>.swiper-wrapper{
   margin-bottom: 0;
   margin-top: 0;
}

.student-progress-photo-bullets {
   bottom: calc(100% - 470px);
   /* bottom: 15%; */
   width: 100%;
}

.swiper-arrow-left,
.swiper-arrow-right {
   cursor: pointer;
}

.swiper-arrow-hide {
   opacity: 0 !important;
   visibility: hidden !important;
   pointer-events: none !important;
}

.swiper-wrapper {
   margin: 1.5rem 0;
}

.no-margin>.swiper-wrapper {
   margin: 0;
}

.community-carousel>.swiper-wrapper {
   margin: 0.8rem 0 6.5rem 0;
}

.portal-student-carousel>.swiper-wrapper {
   margin-bottom: 0;
}

.portal-student-carousel-bullets {
   bottom: calc(100% - 650px);
   left: 0;
   width: 100%;
}

.portal-discontinue-carousel>.swiper-wrapper {
   margin-bottom: 0;
}

.portal-discontinue-carousel-bullets {
   bottom: calc(100% - 650px);
   left: 0;
   width: 100%;
}

.congo-student-carousel-bullets {
   bottom: calc(100% - 365px);
   left: 0;
   width: 100%;
}

/* PSC carousel - phone */
.psc-student-carousel-bullets {
   bottom: calc(100% - 560px);
   left: 0;
   width: 100%;
}

/* PSC carousel - pc */
.psc-student-carousel-pc-bullets {
   bottom: -10px;
   left: 0;
   width: 100%;
}

.discontinue-student-carousel-bullets {
   bottom: 230px;
   left: 0;
   width: 100%;
}

/* .swiper-horizontal>.swiper-pagination>.swiper-pagination-bullet {
   margin: 0 2px !important;
} */

.swiper-overflow-container>.swiper {
   overflow: visible !important;
}

.glider-track {
   margin: 1.5rem !important;
}

.glider::-webkit-scrollbar {
   height: 0px !important;
}

.slider-flickity>.flickity-viewport>.flickity-slider>.flickity-cell {
   margin-right: 1.5rem;
}

.slider-flickity>.flickity-viewport>.flickity-slider>.flickity-cell:first-child {
   padding-left: 20px;
}

.slider-flickity>.flickity-viewport>.flickity-slider>.flickity-cell:last-child {
   padding-right: 20px;
   margin-right: 0rem;
}

/* Masonry */
.my-masonry-grid {
   display: -webkit-box;
   /* Not needed if autoprefixing */
   display: -ms-flexbox;
   /* Not needed if autoprefixing */
   display: flex;
   margin-left: -20px;
   /* gutter size offset */
   width: auto;
}

.my-masonry-grid_column {
   padding-left: 20px;
   /* gutter size */
   background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
   /* change div to reference your elements you put in <Masonry> */
   margin-bottom: 20px;
}

@media (max-width: 321px) {

   p,
   span,
   textarea,
   textarea::placeholder,
   .form-control {
      font-size: 0.75em;
      line-height: 23px;
   }
}

@media (max-width: 385px) {
   /* .portal-student-carousel-bullets {
      bottom: 165px;
      left: 0;
      width: 100%;
   } */

   /* .congo-student-carousel-bullets {
      bottom: 255px;
      left: 0;
      width: 100%;
   } */

   .discontinue-student-carousel-bullets {
      bottom: 245px;
      left: 0;
      width: 100%;
   }
}

@media (min-width: 1025px) {
   .bumper {
      display: none;
   }

   .break-out-container {
      width: auto;
      margin-inline-start: auto;
      transform: none;
   }

   p,
   span,
   textarea,
   textarea::placeholder,
   .form-control {
      font-size: 1rem;
      line-height: 25px;
   }

   .discontinue-student-carousel-bullets {
      display: none;
   }

   .ay-rounded-sm-max {
      border-radius: 0px;
   }

   .py-xl-6 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important;
   }

   .px-xl-6 {
      padding-left: 6rem !important;
      padding-right: 6rem !important;
   }

   .embla__school_slide {
      flex: 0 0 21%;
      /* Slide covers 50% of the viewport */
      min-width: 0;
      margin-right: 10px;
      margin-left: 10px;
   }

   #button-tooltip-trx>.tooltip-inner {
      max-width: 340px;
   }
}

.downward-triangle {
   z-index: 100;
   width: 0;
   height: 0;
   border-style: solid;
   border-width: 15px 10px 0 10px;
   border-color: #ffffff transparent transparent transparent;
   margin-top: -1px;
   margin-left: 25px;
}

@media (min-width: 1280px) {
   .swiper-slide>.swiper-attendance-carousel {
      margin-right: 20px !important;
   }

   .swiper-slide>.swiper-report-card-carousel {
      margin-right: 7px !important;
   }

}

::-webkit-resizer {
   display: none;
}

.ytp-impression-link{
   display: none !important;
}

.commumnity-flex-fixed-width {
   flex: 0 0 auto;
}

.commmunity-flex-fill-rest {
   flex: 1 0 auto;
}


/* student tab carousel */
.swiper__student-photo>.swiper-wrapper{
   margin-bottom: 0;
   margin-top: 0;
}

.swiper__student-photo-bullets {
   /* bottom: calc(100% - 370px); */
   bottom: calc(100% - 26.5em);
   /* bottom: 15%; */
   width: 100%;
}

.swiper__arrow-left-student-photo {
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 94%);
}

.swiper__arrow-right-student-photo {
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 6%);
}

/* invite page students carousel */
.swiper__invite-page--swiper-wrapper>.swiper-wrapper {
   margin-top: 0 !important;
   margin-bottom: 0 !important;
}

.swiper__invite-page--swiper-wrapper>.swiper-pagination {
   margin-top: 0rem !important;
   margin-bottom: 0rem !important;
}

/* from swiper__invite-page--pagination- pagination modifier class */
.swiper__invite-page--pagination-bullets {
   bottom: 6px;
   width: 100%;
   /* uncomment to debug 👇 */
   /* background-color: red; */
}

.swiper__invite-page--right-arrow-btn {
   display: none;
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 8%);
}
.swiper__invite-page--left-arrow-btn {
   display: none;
   cursor: pointer;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) !important;
   left: calc(100% - 92%);
}
@media (min-width: 640px) {
   .swiper__invite-page--right-arrow-btn:not(.swiper-arrow-hide),
   .swiper__invite-page--left-arrow-btn:not(.swiper-arrow-hide) {
      display: inline-block !important;
   }
}

/* grad carousel */
.grad-carousel>.swiper-wrapper {
   margin-bottom: 0;
}

.grad-carousel-bullets {
   bottom: calc(100% - 530px);
   left: 0;
   width: 100%;
}